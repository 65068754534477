/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserRoleDetailFragment = {
  __typename?: 'UserRole';
  id: any;
  archivedAt?: any | null;
  name: string;
  isProtected: boolean;
  meAllowedOperations: Array<Types.PermissionOperationChoices>;
  permissions: Array<{
    __typename?: 'UserRolePermission';
    section: Types.PermissionSectionChoices;
    operation: Types.PermissionOperationChoices;
    scope: Types.PermissionScopeChoices;
  }>;
};

export type UserRoleDetailQueryVariables = Types.Exact<{
  userRoleDetailId: Types.Scalars['UserRoleID']['input'];
}>;

export type UserRoleDetailQuery = {
  __typename?: 'Query';
  object:
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null }
    | {
        __typename?: 'UserRole';
        id: any;
        archivedAt?: any | null;
        name: string;
        isProtected: boolean;
        meAllowedOperations: Array<Types.PermissionOperationChoices>;
        permissions: Array<{
          __typename?: 'UserRolePermission';
          section: Types.PermissionSectionChoices;
          operation: Types.PermissionOperationChoices;
          scope: Types.PermissionScopeChoices;
        }>;
      };
};

export const UserRoleDetailFragmentDoc = gql`
  fragment UserRoleDetail on UserRole {
    id
    archivedAt
    name
    permissions {
      section
      operation
      scope
    }
    isProtected
    meAllowedOperations
  }
`;
export const UserRoleDetailDocument = gql`
  query UserRoleDetail($userRoleDetailId: UserRoleID!) {
    object: userRoleDetail(id: $userRoleDetailId) {
      ... on ObjectDoesNotExistResponse {
        objectId
      }
      ... on UserRole {
        ...UserRoleDetail
      }
    }
  }
  ${UserRoleDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UserRoleDetailGQL extends Apollo.Query<UserRoleDetailQuery, UserRoleDetailQueryVariables> {
  override document = UserRoleDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
